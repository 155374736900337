// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-template-blog-template-js": () => import("./../../../src/page-template/blog-template.js" /* webpackChunkName: "component---src-page-template-blog-template-js" */),
  "component---src-page-template-categories-template-js": () => import("./../../../src/page-template/categories-template.js" /* webpackChunkName: "component---src-page-template-categories-template-js" */),
  "component---src-page-template-sound-detail-js": () => import("./../../../src/page-template/sound-detail.js" /* webpackChunkName: "component---src-page-template-sound-detail-js" */),
  "component---src-page-template-tag-js": () => import("./../../../src/page-template/tag.js" /* webpackChunkName: "component---src-page-template-tag-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

